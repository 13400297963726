@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Edu+AU+VIC+WA+NT+Guides:wght@400..700&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');




body {
  margin: 0;
  line-height: normal;
  
  /* background-color: #f5f5f5;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 1px, transparent 1px), linear-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 1px);
  background-size: 100px 100px;
  height: 100vh; */
}
:root {
  /* fonts */
  --font-archivo: Archivo;
  --text-regular-normal: Roboto;
  --font-poppins: Poppins;
  --android-button: "Balsamiq Sans";

  /* font sizes */
  --font-size-xs: 12px;
  --android-button-size: 14px;
  --font-size-3xs: 10px;
  --font-size-2xs: 11px;
  --font-size-lg: 18px;
  --font-size-4xs-6: 8.6px;
  --text-regular-normal-size: 16px;
  --font-size-xl: 20px;
  --font-size-base-1: 15.1px;
  --font-size-31xl: 50px;
  --font-size-21xl: 40px;
  --font-size-11xl: 30px;
  --font-size-base-4: 15.4px;
  --font-size-mid-6: 17.6px;
  --font-size-lgi-8: 19.8px;
  --font-size-33xl-8: 52.8px;
  --font-size-23xl: 42px;
  --font-size-13xl: 32px;
  --font-size-5xl: 24px;
  --font-size-30xl: 49px;
  --font-size-103xl: 122px;
  --font-size-119xl: 138px;
  --font-size-mid: 17px;
  --font-size-7xl: 26px;
  --font-size-lgi: 19px;
  --font-size-6xs-5: 6.5px;
  --font-size-7xs-8: 5.8px;
  --font-size-8xs: 5px;
  --font-size-6xs-7: 6.7px;
  --font-size-8xs-2: 4.2px;
  --font-size-8xs-4: 4.4px;
  --font-size-6xs-3: 6.3px;
  --font-size-5xs: 8px;
  --font-size-5xs-1: 7.1px;
  --font-size-5xs-5: 7.5px;
  --font-size-8xs-3: 4.3px;
  --font-size-77xl: 96px;
  --font-size-29xl: 48px;
  --font-size-10xl: 29px;
  --font-size-16xl: 35px;
  --font-size-19xl: 38px;
  --font-size-39xl: 58px;
  --font-size-27xl: 46px;
  --font-size-mini-2: 14.2px;
  --font-size-mini: 15px;

  /* Colors */
  --white: #fff;
  --text-primary: #000;
  --color-neutral-neutral-darker: #222;
  --black: #1a1a1a;
  --color-gray-100: #180229;
  --color-gray-200: #01040e;
  --color-gray-300: rgba(255, 255, 255, 0.1);
  --color-gray-400: rgba(24, 2, 41, 0.5);
  --color-gray-500: rgba(255, 255, 255, 0.9);
  --color-gray-600: rgba(255, 255, 255, 0.91);
  --color-gray-700: rgba(26, 26, 26, 0.32);
  --color-gray-800: rgba(1, 4, 14, 0.95);
  --color-gray-900: rgba(1, 4, 14, 0.9);
  --color-gray-1000: rgba(1, 4, 14, 0.25);
  --color-gray-1100: rgba(0, 0, 0, 0.8);
  --gray-300: #f2f2f2;
  --color-whitesmoke-100: rgba(245, 245, 245, 0.8);
  --color-neutral-neutral: #666;
  --color-dimgray-100: #565656;
  --border-tertiary: #444;
  --color-darkslategray-100: #424040;
  --color-darkslategray-200: #373737;
  --color-darkslategray-300: rgba(55, 55, 55, 0.1);
  --color-darkorange: #ea8f23;
  --color-plum: rgba(223, 155, 192, 0.03);
  --color-gainsboro: #e3e3e3;
  --color-purple: #800080;
  --color-green: #008000;
  --gray-200: #b3b3b3;
  --color-midnightblue-100: #131245;
  --color-midnightblue-200: #1f0037;
  --border-success: #027a48;
  --color-mediumpurple: rgba(184, 159, 255, 0.15);

  /* Gaps */
  --gap-9xl-5: 28.5px;
  --gap-xl: 20px;
  --gap-2xl-3: 21.3px;
  --gap-sm: 14px;
  --gap-2xs-7: 10.7px;
  --gap-158xl: 177px;
  --gap-69xl: 88px;
  --gap-23xl: 42px;
  --gap-2xl: 21px;
  --gap-12xs: 1px;
  --gap-5xs-5: 7.5px;
  --gap-5xl: 24px;
  --gap-9xs-6: 3.6px;
  --gap-6xs: 7px;
  --gap-14xl: 33px;
  --gap-mid: 17px;
  --gap-47xl-9: 66.9px;
  --gap-xl-1: 20.1px;
  --gap-7xl-8: 26.8px;
  --gap-3xs: 10px;
  --gap-25xl: 44px;
  --gap-3xl: 22px;
  --gap-7xl-4: 26.4px;
  --gap-xs: 12px;
  --gap-4xs-8: 8.8px;
  --gap-smi-1: 12.1px;
  --gap-5xs: 8px;
  --gap-base: 16px;
  --gap-10xs-2: 2.2px;
  --gap-mini-8: 14.8px;
  --gap-8xs-1: 4.1px;
  --gap-11xs-7: 1.7px;
  --gap-mini: 15px;
  --gap-2xs-1: 10.1px;
  --gap-11xs-6: 1.6px;
  --gap-15xl-7: 34.7px;
  --gap-46xl-3: 65.3px;
  --gap-mini-1: 14.1px;
  --gap-8xs: 5px;
  --gap-10xs-9: 2.9px;
  --gap-xs-6: 11.6px;
  --gap-7xs-1: 5.1px;
  --gap-10xs-5: 2.5px;
  --gap-mini-2: 14.2px;
  --gap-9xs: 4px;
  --gap-7xl: 26px;
  --gap-7xs: 6px;
  --gap-8xs-6: 4.6px;
  --gap-6xs-9: 6.9px;
  --gap-9xs-5: 3.5px;
  --gap-10xs: 3px;
  --gap-9xl: 28px;
  --gap-117xl: 136px;
  --gap-33xl: 52px;
  --gap-3xs-2: 9.2px;
  --gap-sm-8: 13.8px;

  /* Paddings */
  --padding-19xl: 38px;
  --padding-12xl: 31px;
  --padding-8xs: 5px;
  --padding-10xs: 3px;
  --padding-xl: 20px;
  --padding-5xs: 8px;
  --padding-17xl: 36px;
  --padding-11xs: 2px;
  --padding-244xl: 263px;
  --padding-152xl: 171px;
  --padding-12xs-1: 0.1px;
  --padding-6xl-4: 25.4px;
  --padding-20xl: 39px;
  --padding-74xl-8: 93.8px;
  --padding-74xl-6: 93.6px;
  --padding-42xl: 61px;
  --padding-xl-1: 20.1px;
  --padding-mini: 15px;
  --padding-base-8: 16.8px;
  --padding-base-7: 16.7px;
  --padding-smi: 13px;
  --padding-base: 16px;
  --padding-12xs: 1px;
  --padding-18xl: 37px;
  --padding-16xl: 35px;
  --padding-sm: 14px;
  --padding-mid-6: 17.6px;
  --padding-9xs-6: 3.6px;
  --padding-xs: 12px;
  --padding-138xl: 157px;
  --padding-sm-2: 13.2px;
  --padding-6xs-5: 6.5px;
  --padding-63xl: 82px;
  --padding-62xl: 81px;
  --padding-15xl: 34px;
  --padding-110xl: 129px;
  --padding-3xl-6: 22.6px;
  --padding-13xl: 32px;
  --padding-5xl: 24px;
  --padding-3xs-5: 9.5px;
  --padding-4xs-5: 8.5px;
  --padding-22xl: 41px;
  --padding-225xl-6: 244.6px;
  --padding-140xl: 159px;
  --padding-84xl: 103px;
  --padding-67xl: 86px;
  --padding-24xl: 43px;
  --padding-2xl: 21px;
  --padding-base-1: 15.1px;
  --padding-2xs: 11px;
  --padding-7xs: 6px;
  --padding-104xl: 123px;
  --padding-6xs-7: 6.7px;
  --padding-9xs-3: 3.3px;
  --padding-8xs-2: 4.2px;
  --padding-5xs-1: 7.1px;
  --padding-4xs-4: 8.4px;
  --padding-4xs: 9px;
  --padding-3xs: 10px;
  --padding-base-3: 15.3px;
  --padding-xl-9: 20.9px;
  --padding-58xl-9: 77.9px;
  --padding-9xs: 4px;
  --padding-21xl: 40px;
  --padding-10xs-8: 2.8px;
  --padding-8xs-4: 4.4px;
  --padding-6xs-3: 6.3px;
  --padding-75xl-9: 94.9px;
  --padding-11xs-3: 1.3px;
  --padding-11xs-2: 1.2px;
  --padding-7xs-1: 5.1px;
  --padding-3xl: 22px;
  --padding-lgi: 19px;
  --padding-4xs-9: 8.9px;
  --padding-8xs-5: 4.5px;
  --padding-6xs-2: 6.2px;
  --padding-12xs-5: 0.5px;
  --padding-10xs-1: 2.1px;
  --padding-12xs-3: 0.3px;
  --padding-7xl: 26px;
  --padding-68xl: 87px;
  --padding-77xl: 96px;
  --padding-702xl: 721px;
  --padding-77xl-5: 96.5px;
  --padding-134xl: 153px;
  --padding-mid: 17px;
  --padding-6xl: 25px;
  --padding-4xl: 23px;
  --padding-686xl: 705px;
  --padding-29xl: 48px;
  --padding-mid-8: 17.8px;
  --padding-45xl: 64px;
  --padding-7xs-5: 5.5px;
  --padding-smi-5: 12.5px;
  --padding-11xs-9: 1.9px;
  --padding-39xl: 58px;
  --padding-10xl: 29px;

  /* Border radiuses */
  --br-11xs: 2px;
  --br-5xs: 8px;
  --br-base: 16px;
  --br-82xl: 101px;
  --br-xs: 12px;
  --br-281xl: 300px;
  --br-5xl: 24px;
  --br-158xl-8: 177.8px;
  --br-11xs-7: 1.7px;
  --br-6xs-7: 6.7px;
  --br-9xs-3: 3.3px;
  --br-13xl: 32px;
  --br-9xs: 4px;
  --br-29xl: 48px;
  --br-4xl-6: 23.6px;
  --br-2xl-1: 21.1px;

  /* Effects */
  --shadow-xxlarge: 0px 32px 64px -12px rgba(0, 0, 0, 0.14);
  --shadow-xlarge: 3px 0 0 #fff, 0 3px 0 #fff, -3px 0 0 #fff, 0 -5px 0 #fff;
}
