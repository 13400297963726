:root {
    --background-color: #ffffff;
    --text-color: #000000;
    --accent-color: #0077ff;
  }
  
  body.dark {
    --background-color: #1a1a2e;
    --text-color: #f0f0f0;
    --accent-color: #4b59f7;
  }
  
  body {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  