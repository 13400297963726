.header-3d-text {
    color: #333;
    font-size: 1.1rem;
    font-weight: bold;
    text-shadow: 
      1px 1px 0px #f5f5f5,
      2px 2px 0px #c8c8c8,
      3px 3px 0px #999999,
      4px 4px 0px #666666,
      5px 5px 0px #333333,
      6px 6px 5px rgba(2, 102, 45, 0.5);
    transition: all 0.3s ease;
  }
  
  .header-3d-text:hover {
    text-shadow: 
      1px 1px 0px #f5f5f5,
      2px 2px 0px #c8c8c8,
      3px 3px 0px #999999,
      4px 4px 0px #666666,
      5px 5px 0px #333333,
      6px 6px 5px rgba(0,0,0,0.7);
    transform: translateY(-2px);
  }
  
  