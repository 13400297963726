.mapsZoomLevels2City,
.typeDivider {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mapsZoomLevels2City {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.typeDivider {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--br-11xs);
  background-color: var(--background-color-primary);
}

.map {
  position: relative;
  font-weight: 500;
  display: inline-block;
  min-width: 22px;
  z-index: 1;
}

.mapTypeButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0 0;
}

.line,
.satellite {
  position: relative;
  z-index: 1;
}

.line {
  height: 30px;
  width: 1px;
  border-right: 1px solid var(--color-whitesmoke-100);
  box-sizing: border-box;
  flex-shrink: 0;
}

.satellite {
  display: inline-block;
  min-width: 40px;
}

.controlsMapType,
.mapTypeButtons1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.mapTypeButtons1 {
  flex-direction: column;
  padding: var(--padding-5xs) 0 0;
  color: var(--color-dimgray-100);
}

.controlsMapType {
  height: 29px;
  overflow-x: auto;
  flex-direction: row;
  padding: 0 var(--padding-5xs);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-5xs-5);
  z-index: 1;
}

.controlsZoomControls {
  width: 28px;
  flex: 1;
  position: relative;
  max-height: 100%;
  object-fit: cover;
}

.controls,
.controlsMapTypeParent,
.zoomContainer {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}

.controls {
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 var(--padding-17xl);
  position: relative;
  z-index: 1;
}

.controlsMapTypeParent,
.zoomContainer {
  flex-direction: row;
  max-width: 100%;
}

.controlsMapTypeParent {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.zoomContainer {
  height: 89px;
  justify-content: flex-start;
  padding: 0 var(--padding-5xs) 0 var(--padding-10xs);
  box-sizing: border-box;
}

.controlsGoogleLogoColor {
  height: 26px;
  width: 66px;
  position: relative;
  z-index: 1;
}

.frameChild {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-whitesmoke-200);
  width: 100%;
  height: 100%;
}

.mapData2019 {
  position: absolute;
  top: 3px;
  left: 5px;
  display: inline-block;
  min-width: 111px;
  z-index: 1;
}

.rectangleParent {
  height: 15px;
  flex: 1;
  position: relative;
  white-space: nowrap;
}

.termsOfUse {
  position: absolute;
  top: 3px;
  left: 5px;
  display: inline-block;
  min-width: 60px;
  z-index: 1;
}

.rectangleGroup {
  height: 15px;
  width: 70px;
  position: relative;
  white-space: nowrap;
}

.reportAMap {
  position: absolute;
  top: 3px;
  left: 5px;
  display: inline-block;
  min-width: 84px;
  z-index: 1;
}

.rectangleContainer {
  height: 15px;
  width: 94px;
  position: relative;
  white-space: nowrap;
}

.controlsGoogleMapsByline,
.logoCredits,
.mapsFullMap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.controlsGoogleMapsByline {
  flex: 1;
  align-items: flex-start;
  gap: var(--gap-12xs);
  min-width: 187px;
  z-index: 1;
}

.logoCredits,
.mapsFullMap {
  align-self: stretch;
  align-items: flex-end;
  gap: var(--gap-23xl);
  text-align: left;
  font-size: var(--font-size-3xs);
  color: var(--color-gray-300);
}

.mapsFullMap {
  /* box-shadow: var(--shadow-large); */
  border-radius: var(--br-7xs);
  /* background-color: var(--background-color-primary); */
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-5xs) 0 0 var(--padding-8xs);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-158xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-2xs);
  color: var(--background-color-primary);
  font-family: var(--text-regular-normal);
}

.contactUs {
  width: fit-content;

  font-size: medium;
  border-radius: var(--br-7xs);
  background-color: orange;
  border: 2px solid white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xl) 56px;
  gap: var(--gap-2xs-7);
  white-space: nowrap;
  color: #fff;
  transition: 1s ease-in-out;
}

.contactUs b {
  color: white;
  font-size: medium;
}

.contactUs img {
  color: #fff;
}

.chevronDownIcon {
  height: 21.3px;
  width: 21.3px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 21px;
}

@media screen and (max-width: 450px) {
  .logoCredits {
    gap: var(--gap-2xl);
    flex-wrap: wrap;
  }

  .mapsFullMap {
    gap: var(--gap-69xl);
  }
}


